<template>
    <div id="app">
        <AppHeader />
        <router-view/>
        <AppFooter />
    </div>
</template>

<script>
import AppHeader from '@/components/navigation/AppHeader';
import AppFooter from '@/components/navigation/AppFooter';

export default {
    name: 'App',
    components: {AppHeader, AppFooter},
  
    created() {
        this.$store.dispatch('fetchLanguages');
        this.$store.dispatch('fetchTexts');
        this.$store.dispatch('fetchProducts');
        this.$store.dispatch('fetchSettings');

        const token = localStorage.getItem('token');

        if (token) {
            // this.$store.dispatch('autoLogin', token);
            const parsedToken = this.parseJwt(token);

            const userId = parsedToken.userId;
            const role = parsedToken.role;
            const storeId = parsedToken.storeId;
            const exp = parsedToken.exp;
            // const tokenId = parsedToken.tokenId;

            const expDate = new Date(exp * 1000);
            const tokenIsExpired = expDate < Date.now();

            if (tokenIsExpired) {
                localStorage.removeItem('token');
                this.$store.commit('SET_AUTHENTICATED', false);
                this.$store.commit('SET_TOKEN_IS_EXPIRED', true);
            } else {
                // this.$store.commit('SET_ORDER_ID', orderId);
                this.$store.commit('SET_USER_ID', userId);
                this.$store.commit('SET_ROLE', role);
                this.$store.commit('SET_STORE_ID', storeId);
                this.$store.commit('SET_AUTHENTICATED', true);
                this.$store.commit('SET_TOKEN_IS_EXPIRED', false);
            }
            if (role === 'Report') {
                this.$router.push('/report/status');
            } else if (role === 'Store') {
                this.$router.push('/product-catalog');
            } else if (role === 'Service') {
                this.$router.push('/service/search-order');
            }
        }
    },

    methods: {
        parseJwt(token) {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        },
    }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

@import "@/global-styles/mixins";
@import "@/global-styles/variables";
@import "@/global-styles/cre8ion-simplegrid";
@import "@/global-styles/flex";
@import "@/global-styles/default";
@import "@/global-styles/forms";
</style>
