import axios from 'axios';
import generateSignature from './signatureService';

const mode = 'production';

const devBaseUrl = 'https://ryr-api.test.tc8l.nl/pr2';
const accBaseUrl = 'https://fr01-wp2-ryrv2-api-acc-as.azurewebsites.net/fr871';
const prodBaseUrl = 'https://fr01-wp2-ryrv2-api-prd-as.azurewebsites.net/fr871';
// const accBaseUrl = 'https://fr-api-acc.reserveyourreward.com/fr871';
// const prodBaseUrl = 'https://fr-api.reserveyourreward.com/fr871';

let apiBaseUrl = prodBaseUrl;

const apiKey = mode === 'development' ? 'AppApiKey' : '7HUmlqqykXnionKuuNcjTcdzXnxUou2MuoxJ9KJYRjyqLUvBGOJ8aFb3s1mr2RTCe';
const secretKey = mode === 'development' ? 'AppSecretKey' : 'Um1oqV0kGeHoFCDXQeyIWfKFX9jy7YDLb1TOMQMaIYePx4p1lLOqtDRpfunOyFAUU';

switch (mode) {
    case 'development':
        apiBaseUrl = devBaseUrl;
        break;
    case 'acc':
        apiBaseUrl = accBaseUrl;
        break;
    case 'production':
        apiBaseUrl = prodBaseUrl;
        break;
    default:
        apiBaseUrl = prodBaseUrl;
        break;
}

const timeHttp = axios.create({ baseURL: apiBaseUrl });
const axiosInstance = axios.create({ baseURL: apiBaseUrl });

axiosInstance.interceptors.request.use(
    async config => {
        try {
            const res = await timeHttp.get('/timestamp');
            const timeStamp = res.data.timeStamp;

            const token = localStorage.getItem('token');
            const key = generateSignature(apiKey, timeStamp, secretKey);
            
            config.headers = {
                'Content-Type': 'application/json',
                'Api-Key': apiKey,
                Signature: key, 
                Authorization: 'Bearer ' + token,
                TimeStamp: timeStamp
            };
        } catch (error) {
            console.log(error);
        }               
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

export default axiosInstance;
