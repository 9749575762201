export const downloadDocument = (responseData, fileType, fileName) => {
    const fileLink = document.createElement('a');
    fileLink.setAttribute('target', '_blank');

    switch (fileType) {
        case 'pdf':
            fileLink.href = window.URL.createObjectURL(new Blob([responseData], { type: 'application/pdf'}))
            fileLink.setAttribute('download', `${fileName}.pdf`);
            break;
        case 'excel':
            fileLink.href = window.URL.createObjectURL(new Blob([responseData], { type: 'application/vnd.ms-excel'}));
            fileLink.setAttribute('download', `${fileName}.xlsx`);
            break;    
        case 'zip':
            fileLink.href = window.URL.createObjectURL(new Blob([responseData], { type: 'application/zip'}));
            fileLink.setAttribute('download', `${fileName}.zip`);
            break;
        default:
            break;
    }
    
    document.body.appendChild(fileLink);
    fileLink.click();
};

export const documentDownloadHeadersConfig = {
    responseType: 'arraybuffer',
    headers: {
        'Accept': 'application/octet-stream',
    }
};