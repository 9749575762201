export default {
    getUserData: (state) => {
        return state.userData;
    },

    getUsername: (state, getters) => {
        // Only firstname
        if (getters.isFirstNameVisible && !getters.isLastNameVisible) {
            const hasFirstname = state.userData.firstName.length;
            if (hasFirstname)  {
                return state.userData.firstName;
            }
            return localStorage.getItem('firstName'); 
        }

        // Only lastname
        if (!getters.isFirstNameVisible && getters.isLastNameVisible) {
            const hasLastname = state.userData.lastName.length;
            if (hasLastname)  {
                return state.userData.lastName;
            }
            return localStorage.getItem('lastName'); 
        }
        
        // Firstname and lastname visible
        const hasFirstname = state.userData.firstName.length;
        const hasLastname = state.userData.lastName.length;
        if (hasFirstname && hasLastname)  {
            return state.userData.firstName + ' ' + state.userData.lastName;
        }
        return localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName');
    },

    getFirstname: (state) => {
        return state.userData.firstName;
    },

    getLastname: (state) => {
        return state.userData.lastName;
    },

    getLoyaltyCardNumber: (state) => {
        return state.userData.loyaltyCardNumber;
    },

    getDigitalCollector: (state) => {
        return state.userData.digitalCollector;
    },

    getEmail: (state) => {
        return state.userData.email;
    },

    getEmailConfirmation: (state) => {
        return state.userData.emailConfirmation;
    },

    getPassword: (state) => {
        return state.userData.password;
    },

    getPasswordConfirmation: (state) => {
        return state.userData.passwordConfirmation;
    },

    getPhone: (state) => {
        return state.userData.phone;
    },

    getMobile: (state) => {
        return state.userData.mobile;
    },

    getTerms: (state) => {
        return state.userData.terms;
    },

    getPrivacyPolicy: (state) => {
        return state.userData.privacyPolicy;
    },

    getUserId: (state) => {
        return state.userId;
    },

    token: (state) => {
        return state.token;
    },

    role: (state) => {
        return state.role;
    },

    storeId: (state) => {
        return state.storeId;
    },

    isAuthenticated: (state) => {
        return state.authenticated;
    },
    
    tokenIsExpired: (state) => {
        return state.tokenIsExpired;
    },

    authErrorsTitle: (state) => {
        return state.authErrorsTitle;
    },

    authErrorsDescription: (state) => {
        return state.authErrorsDescription;
    },
}