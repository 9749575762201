import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/account/Login.vue'

import store from '../store/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/report/status',
    name: 'Status',
    component: () => import(/* webpackChunkName: "report-status" */ '../views/report/Status.vue')
  },
  {
    path: '/report/store-overview',
    name: 'Store overview',
    component: () => import(/* webpackChunkName: "report-store-overview" */ '../views/report/StoreOverview.vue')
  },
  {
    path: '/report/order-list',
    name: 'Order list',
    component: () => import(/* webpackChunkName: "report-order-list" */ '../views/report/OrderList.vue')
  },
  // {
  //   path: '/report/order-dump',
  //   name: 'Order dump',
  //   component: () => import(/* webpackChunkName: "report-order-dump" */ '../views/report/OrderDump.vue')
  // },
  {
    path: '/report/baseline',
    name: 'Baseline',
    component: () => import(/* webpackChunkName: "report-baseline" */ '../views/report/BaseLine.vue')
  },
  {
    path: '/report/orders-per-store',
    name: 'Orders per store',
    component: () => import(/* webpackChunkName: "report-orders-per-store" */ '../views/report/OrdersPerStore.vue')
  },
  {
    path: '/service/search-order',
    name: 'Lookup reservation',
    component: () => import(/* webpackChunkName: "search-store" */ '../views/order/SearchOrder.vue')
  },
  {
    path: '/product-catalog',
    name: 'Product Catalog',
    component: () => import(/* webpackChunkName: "product-catalog" */ '../views/catalog/ProductCatalog.vue')
  },
  {
    path: '/place-reservation/summary',
    name: 'Summary',
    component: () => import(/* webpackChunkName: "order-summary" */ '../views/order/OrderSummary.vue')
  },
  {
    path: '/place-reservation/select-store',
    name: 'Select Store',
    component: () => import(/* webpackChunkName: "select-store" */ '../views/order/StoreSelector.vue')
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/Faq.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/cookie-policy',
    name: 'Cookie Policy',
    component: () => import(/* webpackChunkName: "cookie-policy" */ '../views/CookiePolicy.vue')
  },
  {
    path: '/adddirectorder',
    name: 'Add Direct Order',
    component: () => import(/* webpackChunkName: "adddirectorder" */ '../views/order/RegisterStepOne.vue')
  },
  {
    path: '/register/step-1',
    name: 'Register Step 1',
    component: () => import(/* webpackChunkName: "register-step-1" */ '../views/order/RegisterStepOne.vue')
  },
  {
    path: '/place-reservation/confirmation',
    name: 'Reservation Confirmation',
    component: () => import(/* webpackChunkName: "reservation-confirmation" */ '../views/order/ReservationConfirmation.vue')
  },
  {
    path: '/not-authorized',
    name: 'Not Authorized',
    component: () => import(/* webpackChunkName: "not-authorized" */ '../views/NotAuthorized.vue')
  },
  {
    path: '/register/step-2',
    name: 'Register Step 2',
    component: () => import(/* webpackChunkName: "register-step-2" */ '../views/order/RegisterStepTwo.vue')
  },
  {
    path: '/register/step-3',
    name: 'Register Step 3',
    component: () => import(/* webpackChunkName: "register-step-3" */ '../views/order/RegisterStepThree.vue')
  },
  {
    path: '/register/thank-you',
    name: 'Thank you',
    component: () => import(/* webpackChunkName: "register-step-3" */ '../views/order/ThankYou.vue')
  },
  {
    path: '/forgot-your-password',
    name: 'Forgot your password',
    component: () => import(/* webpackChunkName: "forgot-your-password" */ '../views/account/ForgotPassword.vue')
  },
  {
    path: '/resetpassword/:resetToken',
    name: 'Reset password',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/account/ResetPassword.vue')
  },
  {
    path: '/edit-order/:orderId',
    name: 'Edit Order',
    component: () => import(/* webpackChunkName: "edit-order" */ '../views/order/EditOrder.vue')
  },
  {
    path: '/*',
    name: 'Page Not Found',
    component: () => import(/* webpackChunkName: "page-not-found" */ '../views/PageNotFound.vue')
  },
]

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  // Redirect to login if not logged in
  if (to.name === 'Product Catalog' && !store.getters.isAuthenticated
    || to.name === 'Status' && !store.getters.isAuthenticated
    || to.name === 'Store overview' && !store.getters.isAuthenticated
    || to.name === 'Order list' && !store.getters.isAuthenticated
    || to.name === 'Order dump' && !store.getters.isAuthenticated
    || to.name === 'Orders per store' && !store.getters.isAuthenticated
    || to.name === 'Lookup reservation' && !store.getters.isAuthenticated
    || to.name === 'Summary' && !store.getters.isAuthenticated
    || to.name === 'Select Store' && !store.getters.isAuthenticated
    || to.name === 'Faq' && !store.getters.isAuthenticated
    || to.name === 'Terms' && !store.getters.isAuthenticated
    || to.name === 'Privacy' && !store.getters.isAuthenticated
    || to.name === 'Cookie Policy' && !store.getters.isAuthenticated
    || to.name === 'Add Direct Order' && !store.getters.isAuthenticated
    || to.name === 'Register Step 1' && !store.getters.isAuthenticated
    || to.name === 'Reservation Confirmation' && !store.getters.isAuthenticated
  ) { 
    next({ name: '/' });
  }
  // Redirect unauthorized users
  if ((to.name === 'Product Catalog' 
    || to.name === 'Lookup reservation' 
    || to.name === 'Summary' 
    || to.name === 'Select Store' 
    || to.name === 'Add Direct Order' 
    || to.name === 'Register Step 1' 
    || to.name === 'Reservation Confirmation') 
    && store.getters.role === 'Report') { 
    next({ name: 'Not Authorized' });
  }
  if (to.name === 'Order list' && store.getters.role === 'Service') { 
    next({ name: 'Not Authorized' });
  }
  if ((to.name === 'Status'
    || to.name === 'Store overview' 
    || to.name === 'Order dump' 
    || to.name === 'Orders per store') 
    && (store.getters.role === 'Store' 
    || store.getters.role === 'Service')) { 
    next({ name: 'Not Authorized' });
  } else {
    next();
  }
});

export default router;
